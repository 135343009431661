import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import 'twin.macro'

//internal
import Layout from "../components/layout"
import SEO from "../components/seo"



const BlogPostTemplate = ({ data, pageContext, location}) => {
  const post = data.contentfulBlogPost
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const desc = data.contentfulBlogPost.description.childMarkdownRemark.excerpt

  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title={post.title} description={desc} />
      <article id="blog" tw="mx-auto">
        <header tw="text-center my-16">
          <h1 tw="font-black text-5xl">
              {post.title}
          </h1>
          <h2> {post.publishDate}</h2>
        </header>
        {
          (post.heroImage == null)
          ? <div></div>
          : <Img fluid={post.heroImage.fluid} alt={post.heroImage.title} tw="rounded max-h-800 " />
        }
        <section dangerouslySetInnerHTML={{ __html: post.body.childMarkdownRemark.html }} tw="md:w-3/5 mx-auto mt-24"/>
        <hr tw="my-8"/>
      </article>

      <nav>
        <ul tw="list-none text-sm mx-0 my-0">
          <li tw="text-center">
            {previous && (
              <Link to={`/blog/${previous.slug}`} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li tw="text-center">
            {next && (
              <Link to={`/blog/${next.slug}`} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    contentfulBlogPost(slug: {eq: $slug}){
      body {
        childMarkdownRemark {
          html
        }
      }
      slug
      title
      publishDate(formatString: "MMMM DD, YYYY")
      description {
        description
        childMarkdownRemark {
          excerpt(pruneLength: 160)
        }
      }
      heroImage {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyContentfulFluid
        }
        title
        description
        file {
          url
        }
      }
      author {
        name
      }
      category {
        name
      }
    }
  }
`
